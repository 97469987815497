import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  width: 50%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  .image-icon{
    height: 31px;
    width: 31px;
  }
  .image-aseo{
    height: 232px;
    width: 242px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }
  @media(max-width: 1000px){
    width: 100%;
  }
`;

const ContainerBox = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;
  justifyContent: space-between;
  @media(max-width: 1000px){
    flex-direction: column;
    justifyContent: flex-start;
    align-items: flex-start;
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Vidrios y Aluminios" />
    <Box>
      <Title title='Vidrios' secondTitle='y Aluminios' />

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Laminados" />
          <h4 class="featurette-SubTitulo">Vidrios Laminados</h4>
        </Container>

        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Traslucidos" />
          <h4 class="featurette-SubTitulo">Vidrios Traslucidos</h4>
        </Container>
      </ContainerBox>

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Templados" />
          <h4 class="featurette-SubTitulo">Vidrios Templados</h4>
        </Container>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Pulidos" />
          <h4 class="featurette-SubTitulo">Vidrios Pulidos</h4>
        </Container>
      </ContainerBox>
      

      

      <ContainerBox>
        <Container >
          <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Biselados" />
          <h4 class="featurette-SubTitulo">Vidrios Biselados</h4>
        </Container>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Perforados" />
          <h4 class="featurette-SubTitulo">Vidrios Perforados</h4>
        </Container>
      </ContainerBox>
      

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Color Bronce" />
          <h4 class="featurette-SubTitulo">Vidrios Color Bronce</h4>
        </Container>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Catedral" />
          <h4 class="featurette-SubTitulo">Vidrios Catedral</h4>
        </Container>
      </ContainerBox>
      

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios Moriscos" />
          <h4 class="featurette-SubTitulo">Vidrios Moriscos</h4>
        </Container>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios de costados de máquinas refrigeredas" />
          <h4 class="featurette-SubTitulo">Vidrios de costados de máquinas refrigeredas</h4>
        </Container>
      </ContainerBox>
      

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Vidrios de estufas tipo Bosca" />
          <h4 class="featurette-SubTitulo">Vidrios de estufas tipo Bosca</h4>
        </Container>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Construcciones en Aluminio" />
          <h4 class="featurette-SubTitulo">Construcciones en Aluminio</h4>
        </Container>
      </ContainerBox>
      
      <br />
      <hr/>
      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Ventanas" />
          <h4 class="featurette-SubTitulo">Ventanas</h4>
        </Container>

        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Mamparas" />
          <h4 class="featurette-SubTitulo">Mamparas</h4>
        </Container>
      </ContainerBox>

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Shower" />
          <h4 class="featurette-SubTitulo">Shower</h4>
        </Container>

        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Box Windowss" />
          <h4 class="featurette-SubTitulo">Box Windowss</h4>
        </Container>
      </ContainerBox>

      <ContainerBox>
        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Puertas" />
          <h4 class="featurette-SubTitulo">Puertas</h4>
        </Container>

        <Container >
          <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Reparaciones" />
          <h4 class="featurette-SubTitulo">Reparaciones</h4>
        </Container>
      </ContainerBox>

      <Container >
        <Image className="image-icon" srcName="tit_servicios.png" width="31px" height="31px" alt="Modificaciones Estructurales" />
        <h4 class="featurette-SubTitulo">Modificaciones Estructurales</h4>
      </Container>
    
      <Container style={{ justifyContent: 'center', width: "100%", justifyContent: 'space-between' }}>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_1.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_2.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_3.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_4.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_5.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_6.png" width="242" alt="Vidrios y Aluminios"/>
        <Image classNamee="image-aseo" srcName="Vidrios_Aluminios_7.png" width="242" alt="Vidrios y Aluminios"/>
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
    
  
